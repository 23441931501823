<template>
  <div class="skin-thickness q-w360 q-fit">
    <question-with-error-wrap :error="getFieldsError(['skinThickness'])">
      <single-answer-question
        title="label.mySkinThickness"
        :value="skinThickness"
        :options="$options.skinThicknessOptions"
        hint="info.skinThickness"
        @input="onFieldChange('skinThickness', $event)"
      />
    </question-with-error-wrap>

    <div class="q-mt24 q-mt32-md flex--center--center">
      <img
        src="@/modules/questionnaire/assets/images/skin-thickness-image.svg"
        alt="Skin Thickness"
      />
    </div>
  </div>
</template>

<script>
import QuestionWithErrorWrap from '@/modules/questionnaire/new-design-components/questions/QuestionWithErrorWrap';
import SingleAnswerQuestion from '@/modules/questionnaire/new-design-components/questions/SingleAnswerQuestion';

import { stepTemplateMixin } from '@/modules/questionnaire/mixins/stepTemplateMixin';

import { SKIN_THICKNESS } from '@/modules/questionnaire/api/constants';

const SKIN_SICKNESS_OPTIONS = [
  { value: SKIN_THICKNESS.THIN, text: 'skinThickness.thin' },
  { value: SKIN_THICKNESS.NORMAL, text: 'skinThickness.normal' },
  { value: SKIN_THICKNESS.THICK, text: 'skinThickness.thick' }
];

export default {
  name: 'SkinThicknessTemplate',
  components: { QuestionWithErrorWrap, SingleAnswerQuestion },
  mixins: [stepTemplateMixin],
  skinThicknessOptions: SKIN_SICKNESS_OPTIONS,
  props: {
    skinThickness: {
      type: String,
      default: ''
    }
  }
};
</script>
